import React, { useCallback, useState } from 'react';
import './ForecastVersionRow.css';
import moment from 'moment';
import { Button, ButtonGroup, ListGroupItem, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { deleteForecastVersion } from './data';

/**
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
const ForecastVersionRow = (props) => {
  const { forecast } = props;

  const [isActive, setActive] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleDeleteClick = useCallback(() => {
    setDeleting(true);
    deleteForecastVersion(forecast.versionId).then(
      (value) => {
        if (value.success) {
          setDeleting(false);
          props.onDeleteForecastVersion(forecast.versionId);
        }
      },
      () => {},
    );
  }, [forecast.versionId, props.onDeleteForecastVersion, setDeleting]);

  /**
   * Change the active state of this component and pass it's ID back to the
   * parent component.
   */
  function toggleActive() {
    props.toggleActive(props.forecast.versionId);
    setActive(!isActive);
  }

  return (
    <ListGroupItem active={isActive} className={'flex'}>
      <div className={'flexColumn'}>
        <p>
          <b>Version Name: </b>
          {forecast.versionName}
        </p>
        <b>Created: </b>
        {moment(forecast.createdDate).fromNow()}
      </div>
      <ButtonGroup className={'flexRow rightButton'}>
        <Button
          outline
          color="secondary"
          size="sm"
          href={'/forecast/results?forecast_version_id[]=' + forecast.versionId}
        >
          View
        </Button>
        {isActive ? (
          <Button outline color="secondary" size="sm" onClick={() => toggleActive()}>
            Remove from Collection
          </Button>
        ) : (
          <Button outline color="secondary" size="sm" onClick={() => toggleActive()}>
            Add to Collection
          </Button>
        )}
        <Button outline color="secondary" size="sm" href={'/forecast/new/' + forecast.versionId}>
          Modify
        </Button>
        <Button
          outline
          color="secondary"
          size="sm"
          onClick={isDeleting ? undefined : handleDeleteClick}
        >
          {isDeleting ? <Spinner size="sm" /> : <>Delete</>}
        </Button>
      </ButtonGroup>
    </ListGroupItem>
  );
};
ForecastVersionRow.propTypes = {
  forecast: PropTypes.object,
  toggleActive: PropTypes.func,
  onDeleteForecastVersion: PropTypes.func,
};
export default ForecastVersionRow;
