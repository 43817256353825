/**
 * action = filterReducer
 *   type: "STRING",
 *   payload: <DATA>>"
 * }
 */
import {
  APPLY_FILTERS,
  UNAPPLY_FILTERS,
  RESET_STATE,
  SET_LOADING,
  UPDATE_FILTER_SELECTIONS,
  UPDATE_FILTER_VALUES,
} from '../actions/filters.action';
import moment from 'moment';
import { dateValues } from '../../app/filters';

export const INITIAL_FILTER_VALUE_STATE = {
  'gift-start-year': [],
  'gift-start-financial-year': [],
  'gift-start-month': dateValues(),
  'transaction-year': [],
  'transaction-financial-year': [],
  'transaction-month': dateValues(),
  frequency: [],
  'gift-status': [],
  channel: [],
  'freebie-status': [],
  fund: [],
  fundraiser: [],
  campaign: [],
  'cancel-reason': [],
  appeal: [],
  supplier: [],
  gender: [],
  'gift-range': [],
  'payment-method': [],
  'age-range': [],
  'last-refreshed': moment().add(-10, 'day'),
  'forecast-reporting-year': [],
  'forecast-channel': [],
  'forecast-supplier': [],
};

export const INITIAL_FILTER_SELECTION_STATE = {
  applied: false,
  loading: false,
  filterSelections: {
    'gift-start-year': [],
    'gift-start-financial-year': [],
    'gift-start-month': false,
    'transaction-year': [],
    'transaction-financial-year': [],
    'transaction-month': false,
    frequency: [],
    'gift-status': [],
    channel: [],
    'freebie-status': [],
    fund: [],
    fundraiser: [],
    campaign: [],
    'cancel-reason': [],
    appeal: [],
    supplier: [],
    gender: [],
    'gift-range': [],
    'payment-method': [],
    'age-range': [],
    'comparison-filters': [],
    'forecast-reporting-year': [],
    'forecast-channel': [],
    'forecast-supplier': [],
  },
};

export function filterSelectionReducer(state = INITIAL_FILTER_SELECTION_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_FILTER_SELECTIONS: {
      return {
        ...state,
        applied: false,
        loading: false,
        filterSelections: {
          ...action.payload.filterSelections,
        },
      };
    }
    case APPLY_FILTERS: {
      return {
        ...state,
        applied: true,
        loading: true,
      };
    }
    case UNAPPLY_FILTERS: {
      return {
        ...state,
        applied: false,
        loading: false,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case RESET_STATE: {
      return INITIAL_FILTER_SELECTION_STATE;
    }
    default:
      return state;
  }
}

export function filterValueReducer(state = INITIAL_FILTER_VALUE_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_FILTER_VALUES: {
      return { ...state, ...action.payload.filterValues };
    }
    case RESET_STATE: {
      return INITIAL_FILTER_VALUE_STATE;
    }
    default:
      return state;
  }
}
