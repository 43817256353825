export const UPDATE_FILTER_SELECTIONS = 'UPDATE_FILTER_SELECTIONS';
export const UPDATE_FILTER_VALUES = 'UPDATE_FILTER_VALUES';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const UNAPPLY_FILTERS = 'UNAPPLY_FILTERS';
export const SET_LOADING = 'SET_LOADING';
export const RESET_STATE = 'RESET_STATE';

export function modifyFilterSelectionsAction(filterSelections) {
  return {
    type: UPDATE_FILTER_SELECTIONS,
    payload: { filterSelections },
  };
}

export function modifyFilterValuesAction(filterValues) {
  return {
    type: UPDATE_FILTER_VALUES,
    payload: { filterValues },
  };
}

export function applyFiltersAction() {
  return {
    type: APPLY_FILTERS,
  };
}

export function unapplyFiltersAction() {
  return {
    type: UNAPPLY_FILTERS,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading: loading,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}
