import React, { useCallback, useEffect, useState } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import Pages from '../../components/Pages';
import { analyticsFilters, getSelectedFilters } from '../../app/filters';
import ReactDataSheet from 'react-datasheet';
import './AnalyticsHallOfFame.css';
import PropTypes from 'prop-types';
import { UncontrolledTooltip, Card } from 'reactstrap';
import InformationCard from '../../components/DataQualityCard';
import { ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, LineChart, Line } from 'recharts';
import {
  downloadSpreadsheet,
  formatValue,
  getColour,
  selectedFilters,
  shadeColour,
} from '../../app/utils';
import promiseAllProperties from 'promise-all-properties';
import { Button } from 'reactstrap';
import { getData, postPayloadData } from '../../app/data';
import moment from 'moment';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardLoading from '../../components/CardLoading';

const getTotalDonors = async (selectedFilters = {}, abortSignal = null) => {
  return getData('get_top_donors_size', selectedFilters, abortSignal).then((res) => {
    if (!res) {
      return 0;
    }
    if (Array.isArray(res)) {
      res = res[0];
    }
    if ('donorCount' in res) {
      return res['donorCount'];
    }
    return 0;
  });
};

const getDonorDetails = async (selectedFilters, abortSignal = null) => {
  return postPayloadData('get_donor_details', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0 || Object.prototype.hasOwnProperty.call(res, 'message')) {
      return {
        age_range: '...',
        average_gift: '...',
        cohort: '...',
        gender: '...',
        latest_gift_date: '...',
        max_gift: '...',
        min_gift: '...',
        total_gift: '...',
      };
    } else {
      return {
        age_range: res[0].age_range === null ? 'N/A' : res[0].age_range.toString(),
        average_gift: formatValue(res[0].average_gift, '$###,###.00'),
        cohort: moment(res[0].cohort).format('MMMM YYYY'),
        gender: res[0].gender,
        latest_gift_date: moment(res[0].latest_gift_date).format('MMMM YYYY'),
        max_gift: formatValue(res[0].max_gift, '$###,###.00'),
        min_gift: formatValue(res[0].min_gift, '$###,###.00'),
        total_gift: formatValue(res[0].total_gift, '###,###'),
      };
    }
  });
};

const getDonorJourney = (selectedFilters, abortSignal = null) => {
  return postPayloadData('get_donor_journey', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res.map((row) => ({
      'Gift Amount': row.gift_amount,
      transactionMonth: moment(row.transactionMonth).format('MMM YYYY'),
    }));
  });
};

/**
 * Analytics / Hall of Fame component
 * @return {JSX} The component rendered.
 */
function AnalyticsHallOfFame(props) {
  const { filterSelections, filterValues } = props;
  const [isPageLoading, setPageLoading] = useState(false);
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [data, setData] = useState({
    donors: [],
    numDonors: 0,
    selectedDonor: {
      age_range: '...',
      average_gift: '...',
      cohort: '...',
      gender: '...',
      latest_gift_date: '...',
      max_gift: '...',
      min_gift: '...',
      total_gift: '...',
    },
    donorJourney: [],
  });
  const [pageNumber, setPageNumber] = useState(1);

  const getHallofFame = useCallback(
    async (selectedFilters = {}, abortSignal = null) => {
      return getData('get_top_donors', selectedFilters, abortSignal).then((res) => {
        // Bool to store if the selected donor was set
        let initialLoad = false;
        const rtn = [
          [
            {
              value: 'Donor ID',
              readOnly: true,
              className: 'table-cell-header',
            },
            {
              value: 'Gender',
              readOnly: true,
              className: 'table-cell-header',
            },
            {
              value: 'Lifetime Gift',
              readOnly: true,
              className: 'table-cell-header',
            },
            {
              value: 'Gift Age (Years)',
              readOnly: true,
              className: 'table-cell-header',
            },
            {
              Value: '',
              readOnly: true,
              className: 'table-cell-header',
            },
          ],
        ];
        if (res === null || res.length === 0) {
          return rtn;
        }
        res.forEach(function (e) {
          if (selectedDonor === null && initialLoad === false) {
            initialLoad = true;
            setSelectedDonor(e.source_donor_id);
          }
          // Regardless, push the data into the return array
          rtn.push([
            {
              value: e['source_donor_id'],
              readOnly: true,
              className: 'table-cell',
            },
            {
              value: e['gender'],
              readOnly: true,
              className: 'table-cell',
            },
            {
              value: formatValue(e['lifetime_revenue'], '$###,##.00'),
              readOnly: true,
              className: 'table-cell',
            },
            {
              value: formatValue(e['gift_age'], '0'),
              readOnly: true,
              className: 'table-cell',
            },
            {
              value: (
                <Button
                  size="sm"
                  color="link"
                  onClick={() => {
                    setSelectedDonor(e.source_donor_id);
                  }}
                >
                  View
                </Button>
              ),
              readOnly: true,
              className: 'table-cell',
            },
          ]);
        });
        return rtn;
      });
    },
    [selectedDonor, setSelectedDonor],
  );

  const getHallOfFameData = useCallback(
    (selectedFilters, offset, abortSignal = null) => {
      selectedFilters['offset'] = offset;
      const promises = {
        donors: getHallofFame(selectedFilters, abortSignal),
        numDonors: getTotalDonors({ ...selectedFilters }, abortSignal),
        selectedDonor: getDonorDetails(
          {
            ...selectedFilters,
            'donor-id': selectedDonor,
          },
          abortSignal,
        ),
        donorJourney: getDonorJourney(
          {
            ...selectedFilters,
            'donor-id': selectedDonor,
          },
          abortSignal,
        ),
      };
      return promiseAllProperties(promises);
    },
    [selectedDonor],
  );

  const handleDownloadSpreadsheet = useCallback(() => {
    const donorData = [
      {
        tabName: 'Donor Journey',
        tabData: data.donorJourney,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet(
      'Hall_of_Fame',
      selectedFilters(filterSelections.filterSelections),
      donorData,
    );
  }, [data, filterSelections.filterSelections]);

  /**
   * Effect run whenever the selected donor changes or filters change.
   * This will reload the hall of fame and relevant donor details as required.
   */
  useEffect(() => {
    if (filterSelections.applied) {
      setPageLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const offset = (pageNumber - 1) * 20;
      const abortController = new AbortController();
      getHallOfFameData(filters, offset, abortController.signal).then((res) => {
        if (!abortController.signal.aborted) {
          setData(res);
          setPageLoading(false);
        }
      });
      return () => abortController.abort();
    }
    // eslint-disable-next-line
  }, [filterSelections, filterValues, analyticsFilters, pageNumber, getHallOfFameData]);

  // determine the upper and lower bounds for the title
  const lowerBound = formatValue((pageNumber - 1) * 20 + 1, '###,##0');
  let upperBound = 0;
  if ((pageNumber - 1) * 20 + 20 < data.numDonors) {
    upperBound = formatValue((pageNumber - 1) * 20 + 20, '###,##0');
  } else {
    upperBound = formatValue(data.numDonors, '###,##0');
  }

  return (
    <div className={'wholepage'}>
      <header>
        <PrimaryBar />
      </header>
      <div className={'main-content'}>
        <SecondaryBar />
        <ContextBar
          title={'Hall of Fame'}
          footer={'Click a donor in the table to show their details on the right.'}
          shortcutFilters={[
            'age-range',
            'appeal',
            'campaign',
            'channel',
            'gift-start-financial-year',
            'payment-method',
            'supplier',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
          preapplied={true}
        />
        {!filterSelections.applied ? (
          <></>
        ) : (
          <div className="content_section no-pad-top">
            <div className="page-content no-pad-top">
              <div className="hall_container">
                <div className="hall_row">
                  <div className="hall_data_section flex">
                    {upperBound !== '0' && (
                      <h3>
                        Donors {lowerBound} to {upperBound}
                      </h3>
                    )}
                    <UncontrolledTooltip
                      target={'top-20-chart'}
                      position={'right'}
                      hideArrow={true}
                    >
                      Your organisation&apos; regular giving donors, sorted by their total
                      donations. Select one to view their details on the right.
                    </UncontrolledTooltip>
                    <Card className="hall_chart" id={'top-20-chart'}>
                      <CardLoading isLoading={isPageLoading}>Loading Page...</CardLoading>
                      <ReactDataSheet data={data.donors} valueRenderer={(cell) => cell.value} />
                    </Card>
                    <Pages dataSetLength={data.numDonors} onChange={setPageNumber} />
                  </div>
                  <div className="hall_data_section cards">
                    <h3>
                      Selected Donor {selectedDonor !== null && <span>({selectedDonor})</span>}
                    </h3>
                    <div className="hall_data_wrapper">
                      <div className="hall_data_content data_full">
                        <div className="data-section margin">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-cohort"
                            toolTipText={
                              'The month & year the donor first started their current subscription.'
                            }
                            title={'Cohort'}
                            measure={data.selectedDonor.cohort}
                          />
                        </div>
                        <div className="data-section margin">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-gender"
                            title={'Gender'}
                            toolTipText={'The provided gender of the donor.'}
                            measure={data.selectedDonor.gender}
                          />
                        </div>
                        <div className="data-section">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-age_range"
                            title={'Age Range'}
                            toolTipText={
                              'The age-range group that the donor fits into as at donation start.'
                            }
                            measure={data.selectedDonor.age_range}
                          />
                        </div>
                      </div>
                      <div className="hall_data_content data_full">
                        <div className="data-section margin">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-average_gift"
                            title={'Average Gift Size'}
                            toolTipText={
                              'The average gift size of the donor throughout their regular giving.'
                            }
                            measure={data.selectedDonor.average_gift}
                          />
                        </div>
                        <div className="data-section margin">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-total_gift"
                            title={'Total Gifts'}
                            toolTipText={
                              'The total number of gifts this donor has made through their regular giving.'
                            }
                            measure={data.selectedDonor.total_gift}
                          />
                        </div>
                        <div className="data-section">
                          <InformationCard
                            componentSelectorName="hall_of_fame-card-latest_gift_date"
                            title={'Most Recent Gift'}
                            toolTipText={'The month and year of their most recent gift.'}
                            measure={data.selectedDonor.latest_gift_date}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="hall-donor-journey-chart">
                      <div className="hall-donor-journey-heading">
                        <h3>Donor Journey</h3>
                        <Button
                          className={'download_spreadsheet'}
                          onClick={handleDownloadSpreadsheet}
                        >
                          <i className="fas fa-download " />
                          Download Spreadsheet
                        </Button>
                      </div>
                      <UncontrolledTooltip
                        target={'donor-journey-chart'}
                        placement={'right'}
                        hideArrow={true}
                      >
                        The chart shows the history of the donors regular giving journey.
                      </UncontrolledTooltip>
                      <Card className="hall-donor-journey-inner" id={'donor-journey-chart'}>
                        <ResponsiveContainer width="100%" height="100%" minHeight={250}>
                          <LineChart
                            data={data.donorJourney}
                            margin={{
                              top: 15,
                              bottom: 50,
                              left: 50,
                              right: 50,
                            }}
                          >
                            <XAxis
                              dataKey="transactionMonth"
                              label={{
                                value: 'Transaction Month',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              tickFormatter={(v) => formatValue(v, '$##0')}
                              label={{
                                value: 'Gift $',
                                angle: -90,
                                position: 'insideLeft',
                              }}
                            />
                            <Tooltip formatter={(v) => formatValue(v, '$##0.##')} />
                            <Legend height={36} verticalAlign="top" />
                            <Line
                              type="monotone"
                              dataKey={'Gift Amount'}
                              stroke={shadeColour(getColour(0), 10)}
                              fill={getColour(0)}
                              strokeWidth={2}
                              dot
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

AnalyticsHallOfFame.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsHallOfFame);
