import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Navigate, useParams } from 'react-router-dom';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ReactDataSheet from 'react-datasheet';
import Datetime from 'react-datetime';
import { Button, Spinner, Alert, UncontrolledTooltip, Input } from 'reactstrap';
import 'react-datasheet/lib/react-datasheet.css';
import 'react-datetime/css/react-datetime.css';
import './ForecastNew.css';
import {
  getDefaultAttritionGrid,
  getDefaultForecastDonorGrid,
  getForecastAttritionGrid,
  getForecastDonorGrid,
  isNan,
  isPercentage,
  parsePercentage,
} from '../../app/utils';
import { getData, postPayloadData } from '../../app/data';
import { getForecastFormData } from './data';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modifyFilterValuesAction } from '../../redux/actions/filters.action';
import { filterDefinition } from '../../app/filters';

function ForecastNew(props) {
  // State variables
  const [alertVisible, setAlertVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [donorGrid, setDonorGrid] = useState(getDefaultForecastDonorGrid());
  const [attritionGrid, setAttritionGrid] = useState(getDefaultAttritionGrid());
  const [avgGift, setAvgGift] = useState(0);
  const [preDebitFail, setPreDebitFail] = useState(0);
  const [cpa, setCpa] = useState(0);
  const [dcc, setDcc] = useState(0);
  const [transactionCost, setTransactionCost] = useState(0);
  const [declinePct, setDeclinePct] = useState(0);
  const [startingMonth, setStartingMonth] = useState('');
  const [versionName, setVersionName] = useState('');
  const [pfra, setPfra] = useState(false);
  const [selectedForecastChannel, setSelectedForecastChannel] = useState(null);
  const [forecastChannels, setForecastChannels] = useState([]);
  const [selectedForecastSupplier, setSelectedForecastSupplier] = useState(null);
  const [forecastSuppliers, setForecastSuppliers] = useState([]);
  const [postSuccess, setPostSuccess] = useState(false);
  const [newForecastVersionId, setNewForecastVersionId] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);

  // Access route parameters using useParams
  const params = useParams();

  // Event handlers
  const onDismiss = () => {
    setAlertVisible(false);
    setErrorMsg('');
  };

  const showAlert = (message) => {
    setIsLoading(false);
    setAlertVisible(true);
    setErrorMsg(message);
  };

  const hideAlerts = () => {
    setAlertVisible(false);
    setErrorMsg('');
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const promises = [];
      const tmpFilterValues = { ...props.filterValues };

      // Check if filters need refreshing
      if (
        moment(props.filterValues['last-refreshed']).diff(moment(), 'minutes') > -60 &&
        props.context === undefined
      ) {
        setForecastChannels(
          props.filterValues['forecast-channel'].map((element) => {
            return { value: element, label: element };
          }),
        );
        setForecastSuppliers(
          props.filterValues['forecast-supplier'].map((element) => {
            return { value: element, label: element };
          }),
        );
        return;
      }

      Object.keys(tmpFilterValues).forEach((key) => {
        const filter = filterDefinition[key];
        if (!filter) return;

        if (filter.type === 'Bool') {
          tmpFilterValues[key] = filter.fixedValues;
        } else if (filter.type === 'ChoiceList') {
          promises.push(
            getData([filter.endpoint], {}).then((res) => {
              tmpFilterValues[key] = res;
            }),
          );
        } else if (filter.type === 'ChoiceListWithContext' && props.context) {
          promises.push(
            getData([filter.endpoint], props.context).then((res) => {
              tmpFilterValues[key] = res;
            }),
          );
        }
      });

      Promise.all(promises).then(() => {
        tmpFilterValues['last-refreshed'] = moment();
        props.modifyFilterValuesAction(tmpFilterValues);
        setForecastChannels(
          tmpFilterValues['forecast-channel'].map((element) => ({
            value: element,
            label: element,
          })),
        );
        setForecastSuppliers(
          tmpFilterValues['forecast-supplier'].map((element) => ({
            value: element,
            label: element,
          })),
        );
      });

      // Load forecast data if forecast_version_id is present in the URL
      if (params && params.forecast_version_id) {
        const forecastVersionId = params.forecast_version_id;
        getForecastFormData(forecastVersionId).then((res) => {
          if (res === null || res.message) return;

          setSelectedForecastChannel({ value: res.channel, label: res.channel });
          setSelectedForecastSupplier({ value: res.supplier, label: res.supplier });
          setAvgGift(res['expectedAverageGift'].toFixed(2));
          setPreDebitFail((res['noFirstGiftPercentage'] * 100).toFixed(2));
          setCpa(res.costPerAcquisition.toFixed(2));
          setDcc(res.donorCareCost.toFixed(2));
          setPfra(res.pfra);
          setTransactionCost((res.transactionCostPercentage * 100).toFixed(2));
          setDeclinePct((res.declinePercentage * 100).toFixed(2));
          setStartingMonth(res.startingForecastMonth);
          setVersionName(res.versionName);
          setDonorGrid(getForecastDonorGrid(res.donorsPerMonth));
          setAttritionGrid(getForecastAttritionGrid(res.attritionPerMonth));
        });
      }
    };
    fetchData();
  }, [props.filterValues, props.context, props.modifyFilterValuesAction, params]);

  // Input change handlers
  const handleAvgGiftChange = (e) => {
    setAvgGift(e.target.value);
    setHasChanged(true);
  };

  const handlePreDebitFailChange = (e) => {
    setPreDebitFail(e.target.value);
    setHasChanged(true);
  };

  const handleCPAChange = (e) => {
    setCpa(e.target.value);
    setHasChanged(true);
  };

  const handleDCCChange = (e) => {
    setDcc(e.target.value);
    setHasChanged(true);
  };

  const handleTransactionCostChange = (e) => {
    setTransactionCost(e.target.value);
    setHasChanged(true);
  };

  const handleDeclinePctChange = (e) => {
    setDeclinePct(e.target.value);
    setHasChanged(true);
  };

  const handleStaringMonthChange = (date) => {
    const formattedDate = moment(date).format('YYYY-MM').toString();
    setStartingMonth(formattedDate);
    setHasChanged(true);
  };

  const handlePFRAChange = (e) => {
    setPfra(e.target.checked);
    setHasChanged(true);
  };

  const handleForecastSupplierChange = (option) => {
    setSelectedForecastSupplier(option);
    setHasChanged(true);
  };

  const handleForecastChannelChange = (option) => {
    setSelectedForecastChannel(option);
    setHasChanged(true);
  };

  const handleVersionNameChange = (e) => {
    setVersionName(e.target.value);
    setHasChanged(true);
  };

  // Form submission handler
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validation logic
    if (isNaN(avgGift) || avgGift < 0) {
      showAlert('Average Gift must be 0 or greater.');
      return;
    }
    if (isNaN(preDebitFail) || preDebitFail < 0 || preDebitFail > 100) {
      showAlert('Pre Debit Fail must be between 0 and 100.');
      return;
    }
    if (isNaN(cpa) || cpa < 0) {
      showAlert('Cost per Acquisition must be 0 or greater.');
      return;
    }
    if (isNaN(dcc) || dcc < 0) {
      showAlert('Donor Care Cost must be 0 or greater.');
      return;
    }
    if (isNaN(transactionCost) || transactionCost < 0 || transactionCost > 100) {
      showAlert('Transaction Cost % must be between 0 and 100.');
      return;
    }
    if (isNaN(declinePct) || declinePct < 0 || declinePct > 100) {
      showAlert('Decline % must be between 0 and 100.');
      return;
    }
    if (!startingMonth) {
      showAlert('Starting Forecast Month must be populated.');
      return;
    }
    if (!versionName) {
      showAlert('Version name is required.');
      return;
    }
    if (!selectedForecastChannel) {
      showAlert('Please select a channel for this forecast.');
      return;
    }
    if (!selectedForecastSupplier) {
      showAlert('Please select a supplier for this forecast.');
      return;
    }

    // Prepare data for submission
    const donorPerMonth = [];
    for (let i = 1; i < donorGrid.length; i++) {
      const row = [];
      for (let j = 1; j < donorGrid[i].length; j++) {
        const cellValue = donorGrid[i][j].value || '0';
        if (isNan(cellValue)) {
          showAlert(`Donors per Month table must only contain numbers. (Row ${i}, Column ${j})`);
          return;
        }
        if (i === 1 && cellValue === '0') {
          showAlert('The first year of Donor data is required.');
          return;
        }
        row.push(cellValue);
      }
      donorPerMonth.push(row);
    }

    const attritionPerMonth = [];
    for (let i = 1; i < attritionGrid.length; i++) {
      const row = [];
      for (let j = 1; j < attritionGrid[i].length; j++) {
        const cellValue = attritionGrid[i][j].value || '0%';
        if (!isPercentage(cellValue)) {
          showAlert(`Attrition table must only contain percentages. (Row ${i}, Column ${j})`);
          return;
        }
        row.push(parsePercentage(cellValue) / 100);
      }
      attritionPerMonth.push(row);
    }

    hideAlerts();

    // Create payload
    const payload = {
      versionName,
      channel: selectedForecastChannel.value,
      supplier: selectedForecastSupplier.value,
      startingForecastMonth: startingMonth,
      averageGift: avgGift,
      noFirstGift: parsePercentage(preDebitFail) / 100,
      costPerAcquisition: cpa,
      pfra,
      donorCareCost: dcc,
      transactionCostPercentage: parsePercentage(transactionCost) / 100,
      declinePercentage: parsePercentage(declinePct) / 100,
      donorsPerMonth: donorPerMonth,
      attritionPerMonth: attritionPerMonth,
    };

    // Submit data
    try {
      const res = await postPayloadData('forecast/add', payload);
      if (res.success) {
        setPostSuccess(true);
        setNewForecastVersionId(res.forecast_version_id);
        setIsLoading(false);
      } else {
        showAlert(res.message || 'An internal error occurred, please try again later.');
      }
    } catch (e) {
      showAlert('An internal error occurred, please try again later.');
    }
  };

  // Redirect after successful submission
  if (postSuccess) {
    const url = `/forecast/results?forecast_version_id[]=${newForecastVersionId}`;
    return <Navigate to={url} />;
  }

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
        <SecondaryBar />
      </header>
      <div className="main-content">
        <div className="forecast">
          <div className="runforcastform_secttion">
            <div className="forecast_container">
              {errorMsg && (
                <Alert color="danger" isOpen={alertVisible} toggle={onDismiss}>
                  {errorMsg}
                </Alert>
              )}
              <h2>Run a New Forecast</h2>
              <div className="fform_wrapper">
                <div className="fleft_form">
                  <div className="fleft_tform">
                    <div className="form-group">
                      <UncontrolledTooltip placement={'right'} target="forecast-channel-label">
                        The channel that will supply donors in this scenario.
                      </UncontrolledTooltip>
                      <label className="forecast_label">Channel</label>
                      <div className="selectdiv" data-cy={'new_forecast-dropdown-channel'}>
                        <CreatableSelect
                          id="forecast-channel-label"
                          className="select-component"
                          options={forecastChannels}
                          value={selectedForecastChannel}
                          onChange={handleForecastChannelChange}
                          formatCreateLabel={(inputValue) => {
                            return `Add New: "${inputValue}"`;
                          }}
                          placeholder="Please Select"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <UncontrolledTooltip placement={'right'} target="forecast-supplier-select">
                        The supplier that will supply donors in this scenario.
                      </UncontrolledTooltip>
                      <label className="forecast_label">Supplier</label>
                      <div
                        className="selectdiv"
                        id="forecast-supplier-select"
                        data-cy={'new_forecast-dropdown-supplier'}
                      >
                        <CreatableSelect
                          className="select-component"
                          options={forecastSuppliers}
                          value={selectedForecastSupplier}
                          onChange={handleForecastSupplierChange}
                          formatCreateLabel={(inputValue) => {
                            return `Add New: "${inputValue}"`;
                          }}
                          placeholder="Please Select"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Include PFRA Cost</label>
                      <UncontrolledTooltip placement={'right'} target="pfra">
                        Include a levy of $1.70 per acquired donor for Public Fundraising Regulatory
                        Association membership.
                      </UncontrolledTooltip>
                      <div className="" id={'pfra'} data-cy={'new_forecast-checkbox-prfa'}>
                        <Input
                          type="checkbox"
                          className={'forecast-new-check-input'}
                          checked={pfra}
                          onChange={handlePFRAChange}
                        />{' '}
                        <label className={'pfra-label'}>$1.70 per acquired donor.</label>
                      </div>
                    </div>
                  </div>
                  <div className="forecast_btn" data-cy={'new_forecast-button-submit'}>
                    <Button
                      className="self_btn yellow_btn"
                      onClick={handleFormSubmit}
                      disabled={!hasChanged}
                    >
                      Submit Forecast
                    </Button>
                    {isLoading && <Spinner color="primary" />}
                  </div>
                  <NavLink end to="/forecast/new" className="clear_link">
                    Clear all and start again
                  </NavLink>
                </div>
                <div className="f_right_form">
                  <div className="frightt_form">
                    <div className="form-group">
                      <label className="forecast_label">Expected Average Gift</label>
                      <UncontrolledTooltip placement={'right'} target="fc_avg_gift">
                        The average gift you agree with your supplier.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-avg_gift'}>
                        <input
                          type="text"
                          name="avg_gift"
                          id="fc_avg_gift"
                          placeholder="0.00"
                          value={avgGift}
                          onChange={handleAvgGiftChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Pre-Debt Fail %</label>
                      <UncontrolledTooltip placement={'right'} target="fc_pre_debit_fail">
                        The average pre-debit failure percentage you predict.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-pre_debit_fail'}>
                        <input
                          type="text"
                          name="pre-debit-fail"
                          placeholder="0.00"
                          id={'fc_pre_debit_fail'}
                          value={preDebitFail}
                          onChange={handlePreDebitFailChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Cost Per Acquisition</label>
                      <UncontrolledTooltip placement={'right'} target="fc_cpa">
                        The average cost per acquisition across all donors you agree with your
                        supplier.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-cpa'}>
                        <input
                          type="text"
                          name="cpa"
                          id={'fc_cpa'}
                          placeholder="0.00"
                          value={cpa}
                          onChange={handleCPAChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Donor Care Cost</label>
                      <UncontrolledTooltip placement={'right'} target="fc_dcc">
                        The average cost of maintaining your donors on a monthly basis. This is the
                        amount for each individual donor, per month.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-donor_care_cost'}>
                        <input
                          type="text"
                          name="dcc"
                          placeholder="0.00"
                          value={dcc}
                          id={'fc_dcc'}
                          onChange={handleDCCChange}
                        />
                      </div>
                      <label className="f_label">$ per donor per month</label>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Transaction Cost %</label>
                      <UncontrolledTooltip placement={'right'} target="fc-tc">
                        The cost in dollars and cents for each transaction from your payment gateway
                        provider and banking institution.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-transaction_cost'}>
                        <input
                          type="text"
                          name="transaction_cost"
                          value={transactionCost}
                          id={'fc-tc'}
                          placeholder="0.00"
                          onChange={handleTransactionCostChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Decline %</label>
                      <UncontrolledTooltip placement={'right'} target="fc-decl">
                        The average percentage of transactions that are declined when requested by
                        the payment gateway provider.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-decline'}>
                        <input
                          type="text"
                          name="decline_pct"
                          id={'fc-decl'}
                          value={declinePct}
                          placeholder="0.00"
                          onChange={handleDeclinePctChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Starting Forecast Month</label>
                      <UncontrolledTooltip placement={'right'} target="startingMonth">
                        The month and year that this campaign will start. This can be in the past or
                        the future.
                      </UncontrolledTooltip>
                      <div
                        className="input_div"
                        id={'startingMonth'}
                        data-cy={'new_forecast-input-start_month'}
                      >
                        <Datetime
                          dateFormat="YYYY-MM"
                          timeFormat={false}
                          type="date"
                          input
                          value={startingMonth}
                          name={'startingMonth'}
                          onChange={handleStaringMonthChange}
                          required={true}
                        />
                      </div>
                      <label className="f_label">Month 1 of Year 1</label>
                    </div>
                    <div className="form-group">
                      <label className="forecast_label">Version Name</label>
                      <UncontrolledTooltip placement={'right'} target="fc-vers">
                        A helpful name for remembering this specific version of this
                        supplier/channel combination. For example, &apos;Higher Decline
                        Forecast&apos;.
                      </UncontrolledTooltip>
                      <div className="input_div" data-cy={'new_forecast-input-version_name'}>
                        <input
                          type="text"
                          name="version_name"
                          id={'fc-vers'}
                          value={versionName}
                          placeholder=""
                          onChange={handleVersionNameChange}
                        />
                      </div>
                      <label className="f_label">Describe this forecast.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="donor_section">
            <div className="donor_container">
              <UncontrolledTooltip placement={'right'} target="fc-dpm-title">
                This table contains 1 cell per month-year of the forecast. Enter the number of
                donors you forecast to acquire in that month so that the total profit & expenses can
                be calculated. The first year is mandatory, if no data is supplied it is
                extrapolated.
              </UncontrolledTooltip>
              <h2>Donors per month</h2>
              <div className="donor_content">
                <div
                  className="donor_table_content"
                  id={'fc-dpm-title'}
                  data-cy={'new_forecast-input-signups'}
                >
                  <ReactDataSheet
                    data={donorGrid}
                    valueRenderer={(cell) => cell.value}
                    onCellsChanged={(changes) => {
                      const dg = donorGrid.map((row) => [...row]);
                      // eslint-disable-next-line no-unused-vars
                      changes.forEach(({ _, row, col, value }) => {
                        dg[row][col] = { ...dg[row][col], value };
                      });
                      setDonorGrid(dg);
                      setHasChanged(true);
                    }}
                  />
                </div>
              </div>
              <div className="attr_section">
                <h2>Attrition</h2>
                <div className="attr_table_section">
                  <UncontrolledTooltip placement={'right'} target="fc-att-title">
                    This table contains 1 cell per month-year of the forecast. Enter the estimated
                    attrition rate throughout the forecast period. The values provided are the
                    benchmarked expected attrition values for donors over a 5 year period.
                  </UncontrolledTooltip>
                  <div
                    className="attr_table_data"
                    id="fc-att-title"
                    data-cy={'new_forecast-input-attrition'}
                  >
                    <ReactDataSheet
                      data={attritionGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) => {
                        const ag = attritionGrid.map((row) => [...row]);
                        // eslint-disable-next-line no-unused-vars
                        changes.forEach(({ _, row, col, value }) => {
                          // Insert a percentage sign at the end of the value
                          if (!value.endsWith('%')) {
                            value += '%';
                          }
                          if (value === '%') {
                            value = '0.00%';
                          }
                          ag[row][col] = { ...ag[row][col], value };
                        });
                        setAttritionGrid(ag);
                        setHasChanged(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    </div>
  );
}

// Redux mappings
const mapStateToProps = ({ filterSelections, filterValues }) => ({
  filterSelections,
  filterValues,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      modifyFilterValuesAction,
    },
    dispatch,
  );

ForecastNew.propTypes = {
  filterValues: PropTypes.object.isRequired,
  filterSelections: PropTypes.object,
  modifyFilterValuesAction: PropTypes.func.isRequired,
  context: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ForecastNew);
