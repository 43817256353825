import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, NavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './PrimaryBar.css';
import { getLogoURL, getClientName, getRole } from '../../app/auth';
import { logUsage } from '../../app/utils';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unapplyFiltersAction } from '../../redux/actions/filters.action';
// import { useLocation } from 'react-router';

/**
 * The Primary Bar
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
function PrimaryBar(props) {
  const { unapplyFilters } = props;
  const [redirect, setRedirect] = useState(undefined);
  let adminDiv = '';
  if (getRole() === 'Administrator') {
    adminDiv = (
      <NavigationElement
        dataCy="navbar-item-settings"
        name="Settings"
        base="/settings"
        link="/settings/members"
        icon="fa fa-address-book"
      />
    );
  }

  async function handleLogout() {
    await logUsage('Logout', 'Authentication', 'Logout');
    setRedirect('/logout');
  }

  if (redirect !== undefined) {
    return <Navigate replace to={redirect} />;
  }

  return (
    <div className="tophead_container">
      <div className="top_header">
        <figure className="main_logo">
          <img src={getLogoURL()} alt="main logo" />
        </figure>
        <div className="top_navmenus">
          <ul className="nav_ul">
            <NavigationElement
              dataCy="navbar-item-home"
              name="Home"
              link="/home"
              base="/home"
              exactMatch
              icon="fas fa-home"
              onClick={unapplyFilters}
            />
            <NavigationElement
              dataCy="navbar-item-forecast"
              name="Forecast"
              base="/forecast"
              link="/forecast/view"
              icon="fas fa-binoculars"
              onClick={unapplyFilters}
            />
            <NavigationElement
              dataCy="navbar-item-analytics"
              name="Analytics"
              base="/analytics"
              link="/analytics/kpi"
              icon="fas fa-chart-bar"
              onClick={unapplyFilters}
            />
            <NavigationElement
              dataCy="navbar-item-help"
              name="Help"
              base="/help"
              link="/help"
              icon="fas fa-life-ring"
              onClick={unapplyFilters}
            />
            {adminDiv}
          </ul>
        </div>
        <div className="user_section">
          <div className="user_logo">
            <UncontrolledDropdown size="sm">
              <DropdownToggle data-cy="navbar-user-dropdown-menu" outline caret>
                <i className="fas fa-user"></i>
                <span>{getClientName()}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>User</DropdownItem>
                <DropdownItem>
                  <NavLink to="/forgot">Change Password</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem data-cy="logout-button" onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * The navigation element
 * @param {Object} props the data for the navigation element
 * @return {JSX}
 */
function NavigationElement(props) {
  const { dataCy, name, link, base, exactMatch, icon, onClick } = props;
  const location = useLocation();
  const startsWith = location.pathname.startsWith(base);
  return (
    <li data-cy={dataCy}>
      <NavLink
        className={() => {
          return (exactMatch && location.pathname === link) || startsWith ? 'active' : undefined;
        }}
        to={link}
        onClick={startsWith ? undefined : onClick}
      >
        <i className={icon} />
        {name}
      </NavLink>
    </li>
  );
}

PrimaryBar.propTypes = {
  unapplyFilters: PropTypes.func,
};

NavigationElement.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  base: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  exactMatch: PropTypes.bool,
  dataCy: PropTypes.string,
  onClick: PropTypes.func,
};

// Redux mappings
const mapStateToProps = ({ filterSelections, filterValues }) => ({
  filterSelections,
  filterValues,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      unapplyFilters: unapplyFiltersAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(PrimaryBar);
