import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ContextBar.css';
import { filterDefinition } from '../../../app/filters';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
  applyFiltersAction,
} from '../../../redux/actions/filters.action';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import ContextFilterBar from '../ContextFilterBar';
import ContextTagSection from '../ContextTagSection';
import { getData } from '../../../app/data';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import CardLoading from '../../CardLoading';

/**
 * ContextFilterTag
 * A atomic filter tag that shows which filters have been applied. Can call the parent component to notify that a
 * clear event has been triggered for the filter.
 * @param {Object} props the data for the filter.
 * @return {JSX} The filter component rendered.
 */
function ContextBar(props) {
  const {
    sidebarFilters,
    shortcutFilters,
    title,
    footer,
    filterValues,
    context,
    filterSelections,
    preapplied,
  } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (preapplied) {
      props.applyFiltersAction();
    }
  }, [preapplied]);

  /**
   * This effect runs when the page first loads, for each filter it gets the valid values of the filter for this
   * user from the database and puts them into the <filter>Values state hook for that filter.
   *
   * Note that the filters values do not change as you apply more filters. This could be changed though if required.
   */
  useEffect(() => {
    setLoading(true);
    const promises = [];
    const tmpFilterValues = filterValues;
    // Check if the filters need refreshing - force a refresh if the parent
    // component supplied a context
    if (
      moment(filterValues['last-refreshed']).diff(moment(), 'minutes') > -60 &&
      context === undefined
    ) {
      setLoading(false);
      return;
    }

    const abortController = new AbortController();
    Object.keys(tmpFilterValues).forEach((key) => {
      const filter = filterDefinition[key];
      if (filter === undefined) {
        return;
      }
      if (filter.type === 'Bool') {
        tmpFilterValues[key] = filter.fixedValues;
      }
      if (filter.type === 'ChoiceList') {
        promises.push(
          getData([filter.endpoint], {}, abortController.signal).then((res) => {
            if (res) {
              tmpFilterValues[key] = res.map((e) => {
                return e;
              });
            }
          }),
        );
      }
      if (filter.type === 'ChoiceListWithContext') {
        if (context !== undefined) {
          promises.push(
            getData([filter.endpoint], context, abortController.signal).then((res) => {
              if (res) {
                tmpFilterValues[key] = res.map((e) => {
                  return e;
                });
              }
            }),
          );
        }
      }
    });
    // Only set loading to false once all promises have finished fetching their data.
    Promise.all(promises).then(() => {
      if (!abortController.signal.aborted) {
        tmpFilterValues['last-refreshed'] = moment();
        props.modifyFilterValuesAction(tmpFilterValues);
        setLoading(false);
      }
    });
    return () => abortController.abort();

    // eslint-disable-next-line
  }, []);

  return (
    <Card className={'shadow-sm filter-card'}>
      <CardHeader className={'context-title'}>{title}</CardHeader>
      <CardBody className={'filter-wrapper'}>
        <CardLoading isLoading={loading}>Loading Filters...</CardLoading>
        <ContextFilterBar
          parentLoading={loading}
          shortcutFilters={shortcutFilters}
          sidebarFilters={sidebarFilters}
        />
        <hr />
        <ContextTagSection shortcutFilters={shortcutFilters} sidebarFilters={sidebarFilters} />
        <hr />
        <div className={'filter-card-apply-filters-btn'}>
          <Button
            onClick={() => {
              props.applyFiltersAction();
            }}
            disabled={filterSelections.applied || loading}
          >
            Run Report
          </Button>
        </div>
      </CardBody>
      <CardFooter>{footer}</CardFooter>
    </Card>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
      applyFiltersAction: applyFiltersAction,
    },
    dispatch,
  );
};

ContextBar.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.string,
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  context: PropTypes.object,
  modifyFilterValuesAction: PropTypes.func,
  modifyFilterSelectionsAction: PropTypes.func,
  applyFiltersAction: PropTypes.func,
  shortcutFilters: PropTypes.arrayOf(PropTypes.string),
  sidebarFilters: PropTypes.arrayOf(PropTypes.string),
  parentLoading: PropTypes.bool,
  preapplied: PropTypes.bool,
};

export default connect(mapStateToProps, mapActionsToProps)(ContextBar);
