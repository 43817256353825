import React, { useState, useEffect } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  Spinner,
  CardBody,
  Button,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetState } from '../../redux/actions/filters.action';

import { login, setSessionInformation, logout } from '../../app/auth';
import './Login.css';
import { logUsage } from '../../app/utils';
import CookieConsentBar from '../../components/CookieConsentBar';

function Login({ registerMsg: initialRegisterMsg, resetState }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [registerMsg, setRegisterMsg] = useState(initialRegisterMsg);
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Reset redux state on mount
    resetState();
  }, [resetState]);

  useEffect(() => {
    // Handle register message from location state
    if (location.state && location.state.registerMsg) {
      setRegisterMsg(location.state.registerMsg);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setIsLoading(true);

    const res = await login(username, password, null);
    if (res && res.message) {
      setIsLoading(false);
      setErrorMsg(res.message);
    } else {
      setSessionInformation(res.token, res.logoURL);
      setLoginSuccess(true);
      logUsage('Login', 'Authentication', 'Login');
    }
  };

  if (loginSuccess) {
    const params = new URLSearchParams(location.search.slice(1));
    if (params.has('redirect')) {
      return <Navigate replace to={params.get('redirect')} />;
    }
    return <Navigate replace to="/home" />;
  } else {
    logout();
  }

  return (
    <div className="container">
      <div className="login-parent">
        <Card className="login-card">
          <CardBody className="login-card-body">
            <h5>Login to your account</h5>
            <br />
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="username" className="black-label">
                  Username
                </Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="on"
                />
              </FormGroup>
              <FormGroup>
                <Label for="password" className="black-label">
                  Password
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="on"
                />
              </FormGroup>
              <FormGroup>
                {errorMsg && (
                  <div>
                    <p className="error-msg">{errorMsg}</p>
                    <br />
                  </div>
                )}
                {registerMsg && <p className="register-msg">{registerMsg}</p>}
                <Row>
                  <Col>
                    <Button data-cy="login-button" type="submit">
                      Login
                    </Button>
                  </Col>
                  <Col>{isLoading && <Spinner color="primary" />}</Col>
                </Row>
                <Row>
                  <Col>
                    <Link to="/register">Register</Link>
                  </Col>
                  <Col>
                    <Link to="/forgot">Forgot My Password</Link>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
      <CookieConsentBar />
    </div>
  );
}

Login.propTypes = {
  registerMsg: PropTypes.string,
  resetState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ filterSelections, filterValues }) => ({
  filterSelections,
  filterValues,
});

const mapDispatchToProps = (dispatch) => ({
  resetState: () => dispatch(resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
