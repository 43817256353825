import promiseAllProperties from 'promise-all-properties';
import { getData, postPayloadData, withComparison, withYearComparison } from '../../app/data';
import moment from 'moment';

const getCalendarData = (selectedFilters, offset, abortSignal = null) => {
  const promises = {
    totalRevenue: getTotalLifetimeRevenue({ ...selectedFilters }, abortSignal),
    signups: getSignupsAtFirstTransaction({ ...selectedFilters }, abortSignal),
    active: getActiveGifts({ ...selectedFilters }, abortSignal),
    latestSignups: getLatestSignupsAtFirstTransaction(selectedFilters, abortSignal),
    dropCount: getDropCount({ ...selectedFilters }, abortSignal),
    latestDropCount: getLatestDropCount(selectedFilters, abortSignal),
    decline: getAverageDecline({ ...selectedFilters }, abortSignal),
    latestDecline: getLatestDecline(selectedFilters, abortSignal),
    revenueByMonth: getRevenueByMonth({ ...selectedFilters }, abortSignal),
    latestRevenue: getLatestTransactionRevenue(selectedFilters, abortSignal),
    donorsPerMonth: getDonorsPerMonth({ ...selectedFilters }, abortSignal),
    signupsByMonth: getSignupsPerMonth({ ...selectedFilters }, abortSignal),
    dropCountByMonth: getDropCountByMonth({ ...selectedFilters }, abortSignal),
    canceledDonors: getCanceledDonors({ ...selectedFilters }, offset, abortSignal),
    totalGiftCount: getTotalGiftCount({ ...selectedFilters }, abortSignal),
    latestTotalGiftCount: getLatestTotalGiftCount(selectedFilters, abortSignal),
    totalGiftCountByMonth: getTotalGiftCountByMonth({ ...selectedFilters }, abortSignal),
    activeGiftCount: getActiveGiftCount({ ...selectedFilters }, abortSignal),
    latestActiveGiftCount: getLatestActiveGiftCount(selectedFilters, abortSignal),
    activeGiftCountByMonth: getActiveGiftCountByMonth({ ...selectedFilters }, abortSignal),
    terminatedGiftCount: getTerminatedGiftCount({ ...selectedFilters }, abortSignal),
    latestTerminatedGiftCount: getLatestTerminatedGiftCount(selectedFilters, abortSignal),
    terminatedGiftCountByMonth: getTerminatedGiftCountByMonth({ ...selectedFilters }, abortSignal),
  };
  return promiseAllProperties(promises);
};

const getTotalLifetimeRevenue = (selectedFilters = {}, abortSignal = null) => {
  return withYearComparison(
    'get_revenue',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.revenue;
    },
    undefined,
    abortSignal,
  );
};

const getSignupsAtFirstTransaction = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_total_signups',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.donorCount;
    },
    undefined,
    abortSignal,
  );
};

const getActiveGifts = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_donor_count?active',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.donorCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestSignupsAtFirstTransaction = (selectedFilters, abortSignal = null) => {
  return getData('get_total_signups?by-month', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      donorCount: res[res.length - 1].donorCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getDropCount = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_canceled_count',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.dropCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestDropCount = (selectedFilters, abortSignal = null) => {
  return getData('get_canceled_count?by-month', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    if (res[res.length - 1].month === null && res.length > 1) {
      return {
        dropCount: res[res.length - 2].dropCount,
        transactionMonth: res[res.length - 2].month,
      };
    }
    return {
      dropCount: res[res.length - 1].dropCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getAverageDecline = (selectedFilters = {}, abortSignal = null) => {
  return withYearComparison(
    'get_average_decline?condense',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.notAccepted / res.transactionCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestDecline = (selectedFilters = {}, abortSignal = null) => {
  return getData('get_average_decline', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      averageDecline: res[res.length - 1].notAccepted / res[res.length - 1].transactionCount,
      transactionMonth: res[res.length - 1].transactionMonth,
    };
  });
};

const getRevenueByMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.transactionMonth).format('MMM YYYY');
    });
    return res;
  };
  return withComparison('get_revenue?by-transaction', selectedFilters, computeFn, abortSignal);
};

const getLatestTransactionRevenue = (selectedFilters = {}, abortSignal = null) => {
  return getData('get_revenue?by-transaction', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      revenue: res[res.length - 1].revenue,
      transactionMonth: res[res.length - 1].transactionMonth,
    };
  });
};

const getDonorsPerMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.transactionMonth).format('MMM YYYY');
    });
    return res;
  };
  return withComparison(
    'get_active_donor_count?by-transaction',
    selectedFilters,
    computeFn,
    abortSignal,
  );
};

const getSignupsPerMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.month).format('MMM YYYY');
    });
    return res;
  };
  return withComparison('get_total_signups?by-month', selectedFilters, computeFn, abortSignal);
};

const getDropCountByMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null ? moment(item.month).format('MMM YYYY') : 'Not supplied';
    });
    return res;
  };
  return withComparison('get_canceled_count?by-month', selectedFilters, computeFn, abortSignal);
};

const getCanceledDonors = (selectedFilters, offset, abortSignal = null) => {
  return postPayloadData(
    'get_canceled_donors',
    {
      ...selectedFilters,
      offset: offset,
    },
    abortSignal,
  ).then((res) => {
    if (res === null || res.length === 0) {
      return { data: [], size: 0 };
    }
    const size = res.size;
    const rtn = [
      [
        { value: 'Donor ID', readOnly: true, className: 'table-cell-header' },
        {
          value: 'Master Gift ID',
          readOnly: true,
          className: 'table-cell-header',
        },
        {
          value: 'Gift End Date',
          readOnly: true,
          className: 'table-cell-header',
        },
        {
          value: 'Cancel Code',
          readOnly: true,
          className: 'table-cell-header',
        },
        {
          value: 'Cancel Reason',
          readOnly: true,
          className: 'table-cell-header',
        },
        {
          value: 'Cancel Comment',
          readOnly: true,
          className: 'table-cell-header',
        },
      ],
    ];
    res.data.forEach((row) => {
      rtn.push([
        { value: row.source_donor_id, readOnly: true, className: 'table-cell' },
        {
          value: row.source_master_gift_id,
          readOnly: true,
          className: 'table-cell',
        },
        {
          value:
            row.gift_end_date !== null
              ? moment(row.gift_end_date).format('MMM YYYY')
              : 'Not supplied',
          readOnly: true,
          className: 'table-cell',
        },
        { value: row.code, readOnly: true, className: 'table-cell' },
        { value: row.reason, readOnly: true, className: 'table-cell' },
        { value: row.comment, readOnly: true, className: 'table-cell' },
      ]);
    });
    return { data: rtn, size: size };
  });
};

const getTotalGiftCount = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_gift_count',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.giftCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestTotalGiftCount = (selectedFilters, abortSignal = null) => {
  return getData('get_gift_count?by-month', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      giftCount: res[res.length - 1].giftCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getTotalGiftCountByMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null ? moment(item.month).format('MMM YYYY') : 'Not supplied';
    });
    return res;
  };
  return withComparison('get_gift_count?by-month', selectedFilters, computeFn, abortSignal);
};

const getActiveGiftCount = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_active_gift_count',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.giftCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestActiveGiftCount = (selectedFilters, abortSignal = null) => {
  return getData('get_active_gift_count?by-month', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      giftCount: res[res.length - 1].giftCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getActiveGiftCountByMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null ? moment(item.month).format('MMM YYYY') : 'Not supplied';
    });
    return res;
  };
  return withComparison('get_active_gift_count?by-month', selectedFilters, computeFn, abortSignal);
};

const getTerminatedGiftCount = (selectedFilters, abortSignal = null) => {
  return withYearComparison(
    'get_terminated_gift_count',
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return res.giftCount;
    },
    undefined,
    abortSignal,
  );
};

const getLatestTerminatedGiftCount = (selectedFilters, abortSignal = null) => {
  return getData('get_terminated_gift_count?by-month', selectedFilters, abortSignal).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return {
      giftCount: res[res.length - 1].giftCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getTerminatedGiftCountByMonth = (selectedFilters, abortSignal = null) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null ? moment(item.month).format('MMM YYYY') : 'Not supplied';
    });
    return res;
  };
  return withComparison(
    'get_terminated_gift_count?by-month',
    selectedFilters,
    computeFn,
    abortSignal,
  );
};

export { getCalendarData, getCanceledDonors };
