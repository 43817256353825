import React, { useCallback, useEffect, useState } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ToggleButton from '../../components/ToggleButton';
import { Button, Card } from 'reactstrap';
import {
  BarChart,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
} from 'recharts';
import ReactDataSheet from 'react-datasheet';
import {
  getDeclinesData,
  getDonorDeclineReasons,
  getDonorDeclinedCount,
  getDonorSuccessiveFailed,
} from './data';
import PropTypes from 'prop-types';
import { analyticsFilters, getComparisonFilter, getSelectedFilters } from '../../app/filters';
import {
  downloadSpreadsheet,
  formatValue,
  getColour,
  selectedFilters,
  shadeColour,
} from '../../app/utils';

import './AnalyticsDeclines.css';
import Pages from '../../components/Pages';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { bindActionCreators } from 'redux';
import { setLoading } from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import { mergeComparison } from '../../app/data';
import LabelledSpinner from '../../components/LabelledSpinner';
import CardLoading from '../../components/CardLoading';

/**
 * Analytics / Declines component
 * @return {JSX} The component rendered.
 */
function AnalyticsDeclines(props) {
  const { filterSelections, filterValues, setLoading } = props;
  const isLoading = filterSelections.loading;
  const [isPageLoading, setPageLoading] = useState(false);
  const [topLeftChart, setTopLeftChart] = useState(0);
  const [topRightChart, setTopRightChart] = useState(0);
  const [table, setTable] = useState(0);
  const [data, setData] = useState({
    declineVolumes: [],
    declinePercentage: {
      isComparison: false,
      results: [],
    },
    lostRevenue: {
      isComparison: false,
      results: [],
    },
    averageTransactions: [],
    declinedCount: { data: [], size: 0 },
    successiveFailed: { data: [], size: 0 },
    declineReasons: { data: [], size: 0 },
  });
  const [tableData, setTableData] = useState({
    data: data.declineReasons.data,
    size: data.declineReasons.size,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [formattedData, setFormattedData] = useState({
    declinePercentage: {
      data: [],
      keys: [],
    },
    lostRevenue: {
      data: [],
      keys: [],
    },
  });

  useEffect(() => {
    if (filterSelections.applied) {
      setLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const offset = (pageNumber - 1) * 20;
      const abortController = new AbortController();
      getDeclinesData(filters, offset, abortController.signal).then((res) => {
        if (!abortController.signal.aborted) {
          setData(res);
          setLoading(false);
        }
      });
      return () => abortController.abort();
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelections.applied, filterSelections.filterSelections, filterValues, analyticsFilters]);

  const handlePageChange = (pageNumber) => {
    if (filterSelections.applied) {
      setPageLoading(true);
      const filters = getSelectedFilters(
        filterSelections.filterSelections,
        filterValues,
        analyticsFilters,
      );
      const currData = data;
      const offset = (pageNumber - 1) * 20;
      setPageNumber(pageNumber);
      switch (table) {
        case 0:
          getDonorDeclineReasons(filters, offset).then((res) => {
            currData.declineReasons = res;
            setData(currData);
            setTableData({
              data: res.data,
              size: res.size,
            });
            setPageLoading(false);
          });
          break;
        case 1:
          getDonorDeclinedCount(filters, offset).then((res) => {
            currData.declinedCount = res;
            setData(currData);
            setTableData({
              data: res.data,
              size: res.size,
            });
            setPageLoading(false);
          });
          break;
        case 2:
          getDonorSuccessiveFailed(filters, offset).then((res) => {
            currData.successiveFailed = res;
            setData(currData);
            setTableData({
              data: res.data,
              size: res.size,
            });
            setPageLoading(false);
          });
          break;
        default:
          break;
      }
    }
  };

  const handleDownloadLostRevenueSpreadsheet = useCallback(() => {
    const chartData = [
      {
        tabName: 'Lost Revenue',
        tabData: formattedData.lostRevenue.data,
        header: ['transactionMonth'],
      },
      {
        tabName: 'Transactions by Day of Week',
        tabData: data.averageTransactions,
        header: ['dayOfWeek', 'Successful', 'Declined'],
      },
    ];

    downloadSpreadsheet(
      `Declines_LostRevenue`,
      selectedFilters(filterSelections.filterSelections),
      chartData,
    );
  }, [data, formattedData, filterSelections.filterSelections]);

  const handleDownloadDeclineVolumesSpreadsheet = useCallback(() => {
    const chartData = [
      {
        tabName: 'Decline Volumes',
        tabData: data.declineVolumes,
        header: ['transactionMonth', 'Successful', 'Declined'],
      },
      {
        tabName: 'Decline Percentage',
        tabData: formattedData.declinePercentage.data,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet(
      `Declines_DeclineVolumes`,
      selectedFilters(filterSelections.filterSelections),
      chartData,
    );
  }, [data, formattedData, filterSelections.filterSelections]);

  useEffect(() => {
    const newData = {
      declinePercentage: {
        data: [],
        keys: [],
      },
      lostRevenue: {
        data: [],
        keys: [],
      },
    };
    newData.lostRevenue = mergeComparison(
      data.lostRevenue,
      'Lost Revenue $',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    newData.declinePercentage = mergeComparison(
      data.declinePercentage,
      'Decline %',
      getComparisonFilter(filterSelections.filterSelections, analyticsFilters),
    );
    setFormattedData(newData);
  }, [data, filterSelections.filterSelections]);

  // Effect that triggers when the table selection is changed, this changes the
  // data loaded into the react data sheet on the right hand side of the route.
  useEffect(() => {
    switch (table) {
      case 0:
        setTableData({
          data: data.declineReasons.data,
          size: data.declineReasons.size,
        });
        break;
      case 1:
        setTableData({
          data: data.declinedCount.data,
          size: data.declinedCount.size,
        });
        break;
      case 2:
        setTableData({
          data: data.successiveFailed.data,
          size: data.successiveFailed.size,
        });
        break;
      default:
        break;
    }
  }, [table, data]);

  return (
    <div className={'wholepage'}>
      <header>
        <PrimaryBar />
      </header>
      <div className={'main-content'}>
        <SecondaryBar />
        <ContextBar
          title={'Declines'}
          footer={'View statistics regarding your declined transactions.'}
          shortcutFilters={[
            'age-range',
            'appeal',
            'campaign',
            'channel',
            'gift-start-financial-year',
            'payment-method',
            'supplier',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
          preapplied={true}
        />
        {filterSelections.applied && isLoading ? (
          <LabelledSpinner
            isLoading={filterSelections.applied && isLoading}
            style={{ width: '5rem', height: '5rem', margin: '0 auto' }}
            color="primary"
          >
            Calculating...
          </LabelledSpinner>
        ) : (
          <></>
        )}
        {!filterSelections.applied || isLoading ? (
          <></>
        ) : (
          <div className="content_section no-pad-top">
            <div className="page-content  no-pad-top">
              <div className="decline-row">
                <div className={'decline-left-section'}>
                  <div className={'chart-section'}>
                    <div className="graph_btns">
                      <ToggleButton
                        text={'Lost Revenue'}
                        active={topRightChart === 0}
                        toolTipText={
                          'The revenue that was not generated due to' +
                          ' declined transactions by month.'
                        }
                        onClick={() => setTopRightChart(0)}
                      />
                      <ToggleButton
                        text={'Transactions by Day of Week'}
                        active={topRightChart === 1}
                        toolTipText={
                          'Declined transactions by the day of week ' +
                          'that the transaction took place.'
                        }
                        onClick={() => setTopRightChart(1)}
                      />
                      <Button
                        className={'download_spreadsheet'}
                        onClick={handleDownloadLostRevenueSpreadsheet}
                      >
                        <i className="fas fa-download " />
                        Download Spreadsheet
                      </Button>
                    </div>
                    <Card className="decline-inner-chart">
                      {topRightChart === 0 ? (
                        <ResponsiveContainer>
                          <LineChart
                            data={formattedData.lostRevenue.data}
                            margin={{
                              top: 50,
                              bottom: 50,
                              left: 50,
                              right: 50,
                            }}
                          >
                            <XAxis
                              dataKey="transactionMonth"
                              label={{
                                value: 'Transaction Month',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              tickFormatter={(v) => formatValue(v, '$##0')}
                              label={{
                                value: 'Lost Revenue $',
                                angle: -90,
                                position: 'insideLeft',
                                offset: -15,
                              }}
                            />
                            <Tooltip formatter={(v) => formatValue(v, '$##0')} />
                            <Legend height={36} verticalAlign="top" />
                            {formattedData.lostRevenue.keys.map((key, index) => (
                              <Line
                                key={`chart_${key}`}
                                type="monotone"
                                dataKey={key}
                                stroke={shadeColour(getColour(index), 10)}
                                fill={getColour(index)}
                                strokeWidth={2}
                                dot
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer>
                          <BarChart
                            data={data.averageTransactions}
                            margin={{
                              top: 50,
                              bottom: 50,
                              left: 50,
                              right: 50,
                            }}
                          >
                            <XAxis
                              dataKey="dayOfWeek"
                              label={{
                                value: 'Day of Week',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              label={{
                                value: 'Transactions',
                                angle: -90,
                                position: 'insideLeft',
                              }}
                            />
                            <Tooltip />
                            <Legend height={36} verticalAlign="top" />
                            <Bar dataKey="Successful" stackId="a" fill="#82ca9d" />
                            <Bar dataKey="Declined" stackId="a" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </Card>
                  </div>
                  <div className={'chart-section'}>
                    <div className="graph_btns">
                      <ToggleButton
                        text={'Decline Volumes'}
                        active={topLeftChart === 0}
                        toolTipText={'Number of failed transactions summarised by instalment.'}
                        onClick={() => setTopLeftChart(0)}
                      />
                      <ToggleButton
                        text={'Decline %'}
                        toolTipText={'Percentage of failed transactions summarised by instalment.'}
                        active={topLeftChart === 1}
                        onClick={() => setTopLeftChart(1)}
                      />
                      <Button
                        className={'download_spreadsheet'}
                        onClick={handleDownloadDeclineVolumesSpreadsheet}
                      >
                        <i className="fas fa-download " />
                        Download Spreadsheet
                      </Button>
                    </div>
                    <Card className="decline-inner-chart">
                      {topLeftChart === 0 ? (
                        <ResponsiveContainer>
                          <BarChart
                            data={data.declineVolumes}
                            margin={{
                              top: 50,
                              bottom: 50,
                              left: 50,
                              right: 50,
                            }}
                          >
                            <XAxis
                              dataKey="transactionMonth"
                              label={{
                                value: 'Transaction Month',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              label={{
                                value: 'Transactions',
                                angle: -90,
                                position: 'insideLeft',
                              }}
                            />
                            <Tooltip />
                            <Legend height={36} verticalAlign="top" />
                            <Bar dataKey="Successful" stackId="a" fill="#82ca9d" />
                            <Bar dataKey="Declined" stackId="a" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer>
                          <LineChart
                            data={formattedData.declinePercentage.data}
                            margin={{
                              top: 50,
                              bottom: 50,
                              left: 50,
                              right: 50,
                            }}
                          >
                            <XAxis
                              dataKey="transactionMonth"
                              label={{
                                value: 'Transaction Month',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              tickFormatter={(v) => formatValue(v, '##0%')}
                              label={{
                                value: 'Decline %',
                                angle: -90,
                                position: 'insideLeft',
                              }}
                            />
                            <Tooltip formatter={(v) => formatValue(v, '##0.00%')} />
                            <Legend height={36} verticalAlign="top" />
                            {formattedData.declinePercentage.keys.map((key, index) => (
                              <Line
                                key={`chart_${key}`}
                                type="monotone"
                                dataKey={key}
                                stroke={shadeColour(getColour(index), 10)}
                                fill={getColour(index)}
                                strokeWidth={2}
                                dot
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </Card>
                  </div>
                </div>
                <div className="decline-right-section">
                  <div className={'table-button-row'}>
                    <ToggleButton
                      text={'Decline Reasons'}
                      active={table === 0}
                      toolTipText={'The top decline reasons for transactions.'}
                      onClick={() => setTable(0)}
                    />
                    <ToggleButton
                      text={'Donor Rankings'}
                      active={table === 1}
                      toolTipText={'The donor with the most declined transactions.'}
                      onClick={() => setTable(1)}
                    />
                    <ToggleButton
                      text={'Successive Declines'}
                      active={table === 2}
                      toolTipText={
                        'Donors with successive declined transactions,' +
                        'ordered greatest to least.'
                      }
                      onClick={() => setTable(2)}
                    />
                  </div>
                  <Card className={'decline-inner-table'}>
                    <CardLoading isLoading={isPageLoading}>Loading Page...</CardLoading>
                    <ReactDataSheet
                      data={tableData.data}
                      className={'decline-inner-table'}
                      valueRenderer={(cell) => cell.value}
                    />
                  </Card>
                  <Pages
                    className={'decline-pages'}
                    onChange={(e) => handlePageChange(e)}
                    dataSetLength={tableData.size}
                    itemsPerPage={20}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLoading: setLoading,
    },
    dispatch,
  );
};

AnalyticsDeclines.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  setLoading: PropTypes.func,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsDeclines);
