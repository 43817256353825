import './LabelledSpinner.css';
import { Spinner } from 'reactstrap';
import React from 'react';
import { PropTypes } from 'prop-types';

const LabelledSpinner = (props) => {
  const { children } = props;

  const spinnerProps = { ...props };
  if (spinnerProps.isLoading !== undefined) {
    delete spinnerProps.isLoading;
  }
  if (children) {
    delete spinnerProps.children;
  }

  return (
    <div className={'labelled-spinner'}>
      <Spinner {...spinnerProps} />
      {children ? <div className={'card-loader-overlay-children'}>{children}</div> : <></>}
    </div>
  );
};

LabelledSpinner.propTypes = {
  children: PropTypes.node,
};

export default LabelledSpinner;
