import './CardLoading.css';
import React from 'react';
import { PropTypes } from 'prop-types';
import LabelledSpinner from '../LabelledSpinner';

const CardLoading = (props) => {
  const { isLoading, children } = props;

  return isLoading ? (
    <div className={'card-loader-overlay'}>
      <LabelledSpinner color={'light'}>{children}</LabelledSpinner>
    </div>
  ) : (
    <></>
  );
};

CardLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default CardLoading;
