import { API_ENDPOINT } from './config';
import { getBearerToken } from './auth';
import { analyticsFilters, getComparisonFilter } from './filters';
import moment from 'moment';
import { getAUFinancialMonthNumber } from './utils';

const getData = (endpoint, selectedFilters = {}, abortSignal = null) => {
  return fetch(API_ENDPOINT + endpoint, {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify(selectedFilters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getBearerToken()}`,
    },
  })
    .then((response) => response.json())
    .catch(() => null);
};

const getFilterSelections = (filters, values) => {
  const selectedFilters = {};
  filters.forEach((f, i) => {
    if (f.type === 'checkbox') {
      selectedFilters[f.queryName] = f.values(values[i]);
    } else {
      selectedFilters[f.queryName] = values[i];
    }
  });
  return selectedFilters;
};

const postPayloadData = (endpoint, data, abortSignal = null) => {
  return fetch(API_ENDPOINT + endpoint, {
    signal: abortSignal,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getBearerToken()}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch(() => null);
};

const getFilterData = (filters, values, additionalData = {}) => {
  const selectedFilters = {
    ...getFilterSelections(filters, values),
    ...additionalData,
  };
  const promises = [];
  filters.forEach((f, i) => {
    if (f.endpoint !== null) {
      const filterPromise = getData(f.endpoint, selectedFilters).then((res) => {
        if (res === null) {
          return [];
        }
        return res;
      });
      promises.push(filterPromise);
    } else {
      promises.push(
        new Promise((res) => {
          res(values[i]);
        }),
      );
    }
  });
  return Promise.all(promises);
};

const withComparison = (endpoint, selectedFilters, computeFn, abortSignal = null) => {
  const compareFilter = getComparisonFilter(selectedFilters, analyticsFilters);
  if (compareFilter !== null) {
    selectedFilters[`${compareFilter}[cmp]`] = selectedFilters[compareFilter];
    delete selectedFilters[compareFilter];
    return getData(endpoint, selectedFilters, abortSignal).then((res) => {
      if (!res) return null;
      if (Array.isArray(res)) {
        res = res[0];
      }
      const results = {};
      for (const compareValue of Object.keys(res)) {
        results[compareValue] = computeFn(res[compareValue]);
      }
      return {
        results: results,
        isComparison: true,
      };
    });
  }

  return getData(endpoint, selectedFilters, abortSignal).then((res) => {
    if (!res) return null;
    return {
      results: computeFn(res),
      isComparison: false,
    };
  });
};

const hasValues = (selectedFilters, filter) => {
  return filter in selectedFilters && selectedFilters[filter].length > 0;
};

const withYearComparison = (
  endpoint,
  selectedFilters,
  computeFn,
  acceptTransactionYear = false,
  abortSignal = null,
) => {
  let comparisonField = null;
  let alternate = null;
  let isFinancial = false;

  if (acceptTransactionYear) {
    const a =
      hasValues(selectedFilters, 'gift-start-year') ||
      hasValues(selectedFilters, 'gift-start-financial-year');
    const b =
      hasValues(selectedFilters, 'transaction-year') ||
      hasValues(selectedFilters, 'transaction-financial-year');
    const first = a && !b;
    const second = !a && b;
    if (first || second) {
      if (second) {
        comparisonField = 'transaction-year';
        alternate = 'transaction-financial-year';
      }
    } else {
      comparisonField = null;
    }
  }

  if (comparisonField !== null) {
    const first =
      hasValues(selectedFilters, comparisonField) && !hasValues(selectedFilters, alternate);
    const second =
      !hasValues(selectedFilters, comparisonField) && hasValues(selectedFilters, alternate);

    if (first || second) {
      if (second) {
        comparisonField = alternate;
        isFinancial = true;
      }
    } else {
      comparisonField = null;
    }
  }

  if (comparisonField in selectedFilters && selectedFilters[comparisonField].length === 1) {
    const currentYear = selectedFilters[comparisonField][0];
    let previousYear = null;
    if (isFinancial) {
      const baseYear = Number(currentYear.split('-')[0]);
      previousYear = `${baseYear - 1}-${baseYear}`;
    } else {
      previousYear = currentYear - 1;
    }
    selectedFilters[`${comparisonField}[cmp]`] = [currentYear, previousYear];
    delete selectedFilters[comparisonField];

    return getData(endpoint, selectedFilters, abortSignal).then((res) => {
      if (!res) return null;
      let currentYearValue = null;
      let previousYearValue = null;
      if (currentYear in res) {
        currentYearValue = computeFn(res[currentYear]);
      }
      if (previousYear in res) {
        previousYearValue = computeFn(res[previousYear]);
      }
      return {
        currentYear: currentYearValue,
        vsPreviousYear: (currentYearValue - previousYearValue) / previousYearValue,
        isComparison: previousYearValue !== null,
      };
    });
  }

  return getData(endpoint, selectedFilters, abortSignal).then((res) => {
    if (!res) return null;
    return {
      currentYear: computeFn(res),
      vsPreviousYear: null,
      isComparison: false,
    };
  });
};

/**
 * Merges a response from the backend that has common transaction months.
 * @param {Object} data the data from the backend
 * @param {String} defaultKey the key to access the value within the response
 * @param {String} comparisonFilter if set to TRANSACTION_YEAR,
 *                 strips the year value from the transactionMonth field
 * @return {Object}
 */
const mergeComparison = (data, defaultKey, comparisonFilter) => {
  const comparisonEnabled = [
    'transaction-year',
    'transaction-financial-year',
    'gift-start-year',
    'gift-start-financial-year',
  ].includes(comparisonFilter);
  const displayData = data.isComparison ? [] : data.results;
  const keys = data.isComparison ? Object.keys(data.results) : [defaultKey];
  if (data.isComparison) {
    const transactionData = {};
    for (const comparisonValue of keys) {
      for (const row of data.results[comparisonValue]) {
        let transactionMonth = row['transactionMonth'];
        if (comparisonEnabled) {
          transactionMonth = transactionMonth.split(' ')[0];
        }
        if (!(transactionMonth in transactionData)) {
          transactionData[transactionMonth] = {
            transactionMonth: transactionMonth,
            [comparisonValue]: row[defaultKey],
          };
        } else {
          transactionData[transactionMonth][comparisonValue] = row[defaultKey];
        }
      }
    }
    let months = Object.keys(transactionData);
    if (comparisonFilter === 'gift-start-year' || comparisonFilter === 'transaction-year') {
      months = months.sort((a, b) => {
        return moment().month(a).format('M') - moment().month(b).format('M');
      });
    } else if (
      comparisonFilter === 'gift-start-financial-year' ||
      comparisonFilter === 'transaction-financial-year'
    ) {
      months = months.sort((a, b) => {
        return getAUFinancialMonthNumber(a) - getAUFinancialMonthNumber(b);
      });
    } else {
      months = months.sort((a, b) => {
        return moment(a).unix() - moment(b).unix();
      });
    }
    for (const month of months) {
      displayData.push(transactionData[month]);
    }
  }
  return {
    data: displayData,
    keys: keys,
  };
};

export {
  withComparison,
  withYearComparison,
  mergeComparison,
  getData,
  getFilterSelections,
  postPayloadData,
  getFilterData,
};
