import React from 'react';
import PropTypes from 'prop-types';
import './ContextTagSection.css';
import { filterDefinition } from '../../../app/filters';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../../redux/actions/filters.action';
import { connect } from 'react-redux';
import ContextTag from '../ContextTag';

/**
 * ContextFilterTag
 * A atomic filter tag that shows which filters have been applied. Can call the parent component to notify that a
 * clear event has been triggered for the filter.
 * @param {Object} props the data for the filter.
 * @return {JSX} The filter component rendered.
 */
function ContextTagSection(props) {
  const { sidebarFilters, filterSelections, filterValues } = props;

  /**
   * Clear an individual filter of its values.
   * @param filterKey The filter to clear all selections from.
   */
  function handleFilterClear(filterKey) {
    const selections = { ...filterSelections.filterSelections };
    const filter = filterDefinition[filterKey];
    const comparisonFilters = [...selections['comparison-filters']];
    selections['comparison-filters'] = comparisonFilters;

    if (filter.type === 'Bool') {
      selections[filterKey] = false;
    } else if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
      if (selections[filterKey].length > 0) {
        selections[filterKey] = [];
      }
      const comparisonFilterIndex = comparisonFilters.indexOf(filterKey);
      if (comparisonFilterIndex > -1) {
        comparisonFilters.splice(comparisonFilterIndex, 1);
      }
    }
    props.modifyFilterSelectionsAction(selections);
  }

  /**
   * For a given filter, fetch the dropdown values that child components expect in an array. When queried, the
   * function will return an array with 1 entry per filter value, with the key set to the filter's name.
   * Each object will also have a selected attribute showing if this value is currently selected.
   * @param filterKey
   * @returns {[]}
   */
  function getValues(filterKey) {
    const rtn = [];
    const filter = filterDefinition[filterKey];
    if (filter.type === 'Bool') {
      if (filterKey in filterValues) {
        rtn.push({
          key: 'Enabled',
          selected: filterSelections.filterSelections[filterKey] === true,
        });
      }
    }

    if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
      if (filterKey in filterValues) {
        Object.keys(filterValues[filterKey]).forEach((index) => {
          rtn.push({
            key: filterValues[filterKey][index],
            selected: filterSelections.filterSelections[filterKey].includes(
              filterValues[filterKey][index],
            ),
          });
        });
      }
    }
    return rtn;
  }

  const filtersSelected = sidebarFilters.some((f) => getValues(f).some((x) => x.selected));

  return (
    <div className={'context-tag-section'}>
      {!filtersSelected ? (
        <div className={'context-tag-section-no-filters'}>No Filters Selected</div>
      ) : (
        sidebarFilters.map((filter) => {
          return (
            <ContextTag
              name={filter}
              handleFilterClear={handleFilterClear}
              key={filter}
              values={getValues(filter)}
            />
          );
        })
      )}
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

ContextTagSection.propTypes = {
  shortcutFilters: PropTypes.arrayOf(PropTypes.string),
  sidebarFilters: PropTypes.arrayOf(PropTypes.string),
  parentLoading: PropTypes.bool,
  modifyFilterSelectionsAction: PropTypes.func,
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ContextTagSection);
