import { API_ENDPOINT } from '../../app/config';
import { getBearerToken } from '../../app/auth';

export const deleteForecastVersion = async (versionId) => {
  return fetch(API_ENDPOINT + 'forecast/remove/' + versionId, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getBearerToken()}`,
    },
  }).then((response) => response.json());
};
