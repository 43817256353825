import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import ContextFilter from '../ContextFilter';
import { filterDefinition } from '../../../app/filters';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../../redux/actions/filters.action';
import { connect } from 'react-redux';
import './ContextFilterBar.css';
import FilterSidebar from '../FilterSidebar';
import * as deepEqual from 'deep-equal';

/**
 * Context Filter Bar
 * The filter Bar at the top of the page that has a highlight of the most important filters for an organisation.
 * @param {Object} props the data for the context filter bar.
 * @return {JSX} The context filter bar component rendered.
 */
function ContextFilterBar(props) {
  const { filterSelections, filterValues, parentLoading, shortcutFilters, sidebarFilters } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  /**
   * This function is fired when a single filters value is changed. When the value changes, an event is fired with
   * the filter's key, the filter value being modified and the new value of the filter.
   * @param filterKey The filter to update the value of.
   * @param valueKey The value of the filter to toggle the value of.
   */
  function handleFilterChange(filterKey, valueKey) {
    const selections = { ...filterSelections.filterSelections };
    const filterSelection = [...selections[filterKey]];
    selections[filterKey] = filterSelection;
    const comparisonFilters = [...selections['comparison-filters']];
    selections['comparison-filters'] = comparisonFilters;

    const filter = filterDefinition[filterKey];
    if (filter.type === 'Bool') {
      selections[filterKey] = !selections[filterKey];
    }
    if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
      const valueIndex = filterSelection.indexOf(valueKey);
      if (valueIndex !== -1) {
        filterSelection.splice(valueIndex, 1);
      } else {
        filterSelection.push(valueKey);
      }
      selections[filterKey] = filterSelection;
      // If the filter is eligible for comparison (more than 1 value)
      if (filterSelection.length > 1) {
        // This is the first time the filter has been applied.
        if (!comparisonFilters.includes(filterKey)) {
          comparisonFilters.push(filterKey);
        }
      } else {
        // The filter is not eligible for comparison, remove it from the list if it exists
        const comparisonIndex = comparisonFilters.indexOf(filterKey);
        if (comparisonIndex !== -1) {
          comparisonFilters.splice(comparisonIndex, 1);
        }
      }
    }
    props.modifyFilterSelectionsAction(selections);
  }

  /**
   * Clear an individual filter of its values.
   * @param filterKey The filter to clear all selections from.
   */
  function handleFilterClear(filterKey) {
    const selections = { ...filterSelections.filterSelections };
    const filter = filterDefinition[filterKey];
    const comparisonFilters = [...selections['comparison-filters']];
    selections['comparison-filters'] = comparisonFilters;

    if (filter.type === 'Bool') {
      selections[filterKey] = false;
    } else if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
      if (selections[filterKey].length > 0) {
        selections[filterKey] = [];
      }
      const comparisonFilterIndex = comparisonFilters.indexOf(filterKey);
      if (comparisonFilterIndex > -1) {
        comparisonFilters.splice(comparisonFilterIndex, 1);
      }
    }
    props.modifyFilterSelectionsAction(selections);
  }

  /**
   * Clear all filters of all values.
   */
  function handleClearAll() {
    const selections = { ...selections };
    Object.keys(selections).forEach((key) => {
      const filter = filterDefinition[key];
      if (filter === undefined) {
        return;
      }
      if (filter.type === 'Bool') {
        selections[key] = false;
      }
      if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
        if (selections[key].length > 0) {
          selections[key] = [];
        }
      }
      props.modifyFilterSelectionsAction(selections);
    });
  }

  /**
   * Return the label of a filter key.
   * @param filterKey The filter to lookup.
   * @returns {string} The label for the filter.
   */
  function getLabel(filterKey) {
    return filterDefinition[filterKey].label;
  }

  /**
   * For a given filter, fetch the dropdown values that child components expect in an array. When queried, the
   * function will return an array with 1 entry per filter value, with the key set to the filter's name.
   * Each object will also have a selected attribute showing if this value is currently selected.
   * @param filterKey
   * @returns {[]}
   */
  function getValues(filterKey) {
    const rtn = [];
    const filter = filterDefinition[filterKey];
    if (filter.type === 'Bool') {
      if (filterKey in filterValues) {
        rtn.push({
          key: 'Enabled',
          selected: filterSelections.filterSelections[filterKey] === true,
        });
      }
    }

    if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
      if (filterKey in filterValues) {
        Object.keys(filterValues[filterKey]).forEach((index) => {
          rtn.push({
            key: filterValues[filterKey][index],
            selected: filterSelections.filterSelections[filterKey].includes(
              filterValues[filterKey][index],
            ),
          });
        });
      }
    }

    return rtn;
  }

  return (
    <div className={'context-filters'}>
      <div className={'filters-row'}>
        {shortcutFilters.map((filter) => {
          return (
            <ContextFilter
              label={getLabel(filter)}
              name={filter}
              key={filter}
              fill={true}
              handleFilterClear={handleFilterClear}
              loading={parentLoading}
              values={getValues(filter)}
              onChange={handleFilterChange}
            />
          );
        })}
      </div>
      {!deepEqual(shortcutFilters, sidebarFilters) && (
        <Button
          className={'more-filters'}
          disabled={parentLoading}
          // fill={true}
          text={'All Filters'}
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        />
      )}
      <FilterSidebar
        title={'More Filters'}
        isOpen={sidebarOpen}
        handleClose={() => setSidebarOpen(false)}
        icon={'search'}
        handleClearFilter={handleFilterClear}
        filterKeys={sidebarFilters}
        getLabel={getLabel}
        getValues={getValues}
        handleFilterChange={handleFilterChange}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button minimal onClick={() => setSidebarOpen(false)} text={'Close'} />
            <Button minimal onClick={() => handleClearAll()} text={'Clear All Filters'} />
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

ContextFilterBar.propTypes = {
  shortcutFilters: PropTypes.arrayOf(PropTypes.string),
  sidebarFilters: PropTypes.arrayOf(PropTypes.string),
  modifyFilterSelectionsAction: PropTypes.func,
  parentLoading: PropTypes.bool,
  filterSelections: PropTypes.object,
  filterValues: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ContextFilterBar);
